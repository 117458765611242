html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden; /* предотвращаем горизонтальный скролл */
}

html {
  background: #fff;
}

body {
  font-family: 'PT Sans', serif;
  position: relative; /* создаём контекст для псевдоэлементов */
  z-index: 0;
  background: transparent; /* делаем фон body прозрачным, чтобы сквозь него был виден блюр */
}

body::before,
body::after {
  content: '';
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 380px;
  height: 380px;
  border-radius: 50%;
  background: rgba(87, 132, 247, 0.25);
  filter: blur(175px);
  pointer-events: none;
  z-index: -1; /* Чтобы они были позади содержимого body */
}

body::before {
  left: -150px; /* Сдвиг влево */
}

body::after {
  right: -420px; /* Сдвиг вправо */
}

/* Остальные стили */
code {
  font-family: 'PT Sans', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
